import { useEffect, useRef, useState } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { useMobile } from 'src/hooks/useMobile'
import { Image } from 'src/components/ui/Image'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import type {
  Video,
  Image as TImage,
} from 'src/components/restructure/product/sections/GalleryImage/types/gallery-image'
import { CloseButtonIcon } from 'src/components/Icons/CloseButtonIcon'

import { mainOptionsGallery, thumbsOptionsGallery } from './utils/splideConfig'
import { GalleryMovie, GalleryMovieThumb } from '../GalleryMovie'
import { Controls } from './utils/Controls'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'
import './index.scss'

type GalleryFullViewProps = {
  isOpen: boolean
  closeGalleryView: () => void
  images: Array<TImage | Video>
  selectedImageForClick: TImage
}

export const GalleryFullView = ({
  isOpen,
  closeGalleryView,
  images,
  selectedImageForClick,
}: GalleryFullViewProps) => {
  const mainRef = useRef<Splide>(null)
  const thumbsRef = useRef<Splide>(null)
  const mainContainerRef = useRef<HTMLDivElement>(null)

  const [showThumbs, setShowThumbs] = useState(true)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(1)

  const { screenWidth } = useMobile()

  const totalItemCount = images.length
  const initialSlideIndex = images.findIndex(
    (image: TImage | Video) => image === selectedImageForClick
  )

  useEffect(() => {
    if (mainRef?.current && thumbsRef.current && thumbsRef.current.splide) {
      mainRef.current.sync(thumbsRef.current.splide)
    }
  })

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement)
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, [])

  const toggleFullscreen = () => {
    if (mainContainerRef.current) {
      if (!document.fullscreenElement) {
        mainContainerRef.current.requestFullscreen().catch((err) => {
          if (err) {
            console.error(
              `Error attempting to enable full-screen mode: ${err.message}`
            )
          }
        })
      } else {
        document.exitFullscreen()
      }
    }
  }

  const toggleThumbs = () => {
    setShowThumbs(!showThumbs)
  }

  useEffect(() => {
    const calculateCurrentImageIndex = (currentIndex: number) => {
      return currentIndex >= totalItemCount ? totalItemCount : currentIndex + 1
    }

    if (mainRef.current) {
      mainRef?.current?.splide?.on('moved', (newIndex) => {
        setCurrentImageIndex(calculateCurrentImageIndex(newIndex))
      })
    }
  })

  useEffect(() => {
    if (initialSlideIndex !== -1 && mainRef.current) {
      mainRef?.current?.splide?.go(initialSlideIndex)
    }
  }, [initialSlideIndex])

  if (!isOpen) {
    return null
  }

  return (
    <div
      data-testid="gallery-full-view"
      className="gallery-full-view justify-between fixed inset-0 items-center z-[9999] bg-restructure-background-primary h-screen]"
      ref={mainContainerRef}
    >
      <div className="w-full flex flex-col items-center m-auto h-full relative justify-between">
        <div
          className={`section-image w-full flex flex-col justify-center items-center h-full ${
            !showThumbs ? 'pt-0' : 'pt-[80px]'
          }`}
        >
          <TransformWrapper maxScale={4} disabled={screenWidth < 600}>
            {(utils) => {
              if (mainRef.current) {
                mainRef?.current?.splide?.on('move', () => {
                  utils.resetTransform(0)
                  utils.centerView()
                })
              }

              return (
                <>
                  <div className="flex justify-center w-full items-center restructure-small-desktop:px-[80px] px-[20px] py-4 pr-0 absolute top-0">
                    <div
                      aria-label={`Contador de imagem: ${currentImageIndex} de ${totalItemCount}`}
                    >
                      {currentImageIndex}/{totalItemCount}
                    </div>
                    <Controls
                      {...utils}
                      isFullscreen={isFullscreen}
                      toggleFullscreen={toggleFullscreen}
                      toggleThumbs={toggleThumbs}
                    />
                    <button
                      className="p-4"
                      data-testid="close-gallery-full-view1"
                      aria-label="Botão fechar da Galeria Full View"
                      onClick={closeGalleryView}
                    >
                      <CloseButtonIcon />
                    </button>
                  </div>
                  <TransformComponent>
                    <Splide
                      hasTrack={false}
                      options={mainOptionsGallery}
                      ref={mainRef}
                      className="h-full main_splide w-full"
                    >
                      <SplideTrack className="h-full">
                        {images?.map((image: TImage | Video, idx: number) => (
                          <SplideSlide key={idx} className="h-full ">
                            {'url' in image ? (
                              <GalleryMovie {...image} />
                            ) : (
                              <Image
                                className="h-full w-full object-cover cursor-grab"
                                key={image.imageUrl}
                                src={image.imageUrl}
                                width={screenWidth < 600 ? 1000 : 1200}
                                height={screenWidth < 600 ? 1000 : 1200}
                                alt={image.imageText}
                                loading="eager"
                                preload
                              />
                            )}
                          </SplideSlide>
                        ))}
                      </SplideTrack>
                    </Splide>
                  </TransformComponent>
                </>
              )
            }}
          </TransformWrapper>
        </div>

        {showThumbs && (
          <div className="w-full max-w-[760px] pl-7 h-[88px] restructure-medium-desktop:pl-0 mb-6 mt-8">
            <Splide
              options={thumbsOptionsGallery}
              ref={thumbsRef}
              className="thumbs-splide w-full md:w-auto"
              hasTrack={false}
            >
              <SplideTrack>
                {images?.map((image: TImage | Video, idx: number) => (
                  <SplideSlide
                    key={idx}
                    className="flex items-center justify-center"
                  >
                    {'url' in image ? (
                      <GalleryMovieThumb {...image} />
                    ) : (
                      <Image
                        key={image.imageText}
                        src={image.imageUrl}
                        width={88}
                        height={88}
                        alt={image.imageText}
                        style={{ objectFit: 'contain' }}
                        loading={idx <= 4 ? 'eager' : 'lazy'}
                        preload={idx <= 4}
                        className="w-[88px] bg-restructure-background-secondary mix-blend-multiply rounded-md"
                      />
                    )}
                  </SplideSlide>
                ))}
              </SplideTrack>
            </Splide>
          </div>
        )}
      </div>
    </div>
  )
}
