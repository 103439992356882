import { useRef, useEffect, useState } from 'react'
import { useMobile } from 'src/hooks/useMobile'
import {
  makeViewListImpressionEvent,
  sendEvent,
} from 'src/utils/restructure/analytics'
import axios from 'axios'

import ProductCard from '../../ProductCard'
import type { Product } from '../../types/product'

import './simpleShelf.scss'

type SimpleShelfProps = {
  products: Product[]
  title: string
  impressionUrl?: string
  sport?: string
}

export const SimpleShelf = ({
  products,
  title,
  impressionUrl,
  sport,
}: SimpleShelfProps) => {
  const { isMobile } = useMobile()
  const observer = useRef<HTMLPreElement>(null)

  const [eventSent, setEventSent] = useState(false)

  useEffect(() => {
    if (!observer.current) {
      return () => {}
    }

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (!entries.some((entry) => entry.isIntersecting)) {
        return
      }

      try {
        if (!eventSent) {
          const event = makeViewListImpressionEvent(
            products.map((item) => ({
              product: item,
              productItem: item?.items[0],
            })),
            {
              listName: `vitrine - ${title} - ${sport ?? undefined}`,
              dimension29: 'linx',
            }
          )

          sendEvent(event)
          setEventSent(true)
        }

        if (impressionUrl) {
          axios.get(impressionUrl)
        }
      } catch (error) {
        console.error(error)
      }
    })

    intersectionObserver.observe(observer?.current)

    return () => intersectionObserver.disconnect()
  }, [eventSent, impressionUrl, products, sport, title])

  return (
    <>
      <pre ref={observer} />
      <div className="simple-shelf w-full max-w-[1280px] mx-auto my-[80px] pl-4 sm:pl-0">
        <h2 className="text-2xl sm:text-[32px] text-restructure-primary font-inter font-medium mb-8 sm:mb-10">
          {title}
        </h2>
        <div className="simple-shelf-items-wrapper flex gap-3 sm:gap-6 overflow-x-auto pb-12 sm:pb0">
          {products.map((product, index) => {
            return (
              <div key={`${product.productId}-${index}`}>
                <ProductCard
                  index={index}
                  product={product}
                  UseModeList={false}
                  drag={!isMobile}
                  shelfEng="linx"
                  shelfRef={`vitrine - Compre Junto - ${
                    product.sport ?? undefined
                  }`}
                />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
