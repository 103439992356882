import { useEffect, useMemo, useState } from 'react'
import {
  subscribe,
  unsubscribe,
} from 'src/components/restructure/product/productEvents'
import { useCepStorage } from 'src/components/common/CepComponent/hooks/CepInformationContext'
import { FreightSimulation } from 'src/components/restructure/common/FreightSimulation'
import { FreightModal } from 'src/components/restructure/common/FreightSimulation/FreightModal'
import { useProductPageContext } from 'src/components/restructure/product/contexts/product-context'

export function Shipping() {
  const { addressStorage } = useCepStorage()
  const { currentSku, specificationMustBeSelected, setFreightSimulation } =
    useProductPageContext()

  const [isSimulationModalOpen, setOpenSimulationModal] = useState(false)

  useEffect(() => {
    if (specificationMustBeSelected) {
      unsubscribe('addToCart', () => setOpenSimulationModal(true))
      subscribe('addToCart', () => setOpenSimulationModal(false))
    }

    if (!addressStorage && !specificationMustBeSelected) {
      unsubscribe('addToCart', () => setOpenSimulationModal(false))
      subscribe('addToCart', () => {
        setOpenSimulationModal(true)
      })
    }

    if (addressStorage && !specificationMustBeSelected) {
      unsubscribe('addToCart', () => setOpenSimulationModal(true))
      subscribe('addToCart', () => {
        setOpenSimulationModal(false)
      })
    }
  }, [addressStorage, specificationMustBeSelected])

  const readyToGetFreightSimulation =
    specificationMustBeSelected === null && addressStorage

  const items = useMemo(
    () => [
      {
        id: currentSku.itemId,
        seller: currentSku.sellerDefault.sellerId,
        quantity: 1,
      },
    ],
    [currentSku]
  )

  useEffect(() => {
    async function runFreightSimulation() {
      const storedCEP = localStorage.getItem('cep')

      if (storedCEP) {
        await setFreightSimulation(items, storedCEP)
      }
    }

    runFreightSimulation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section id="shipping">
      <div className="flex items-center flex-wrap gap-y-md justify-between p-md rounded-sm bg-restructure-background-secondary">
        {addressStorage && (
          <p className="desktop-caption-regular text-restructure-secondary">
            <span>
              {addressStorage.city} / {addressStorage.state}
            </span>
            <span className="block">{addressStorage.postalCode}</span>
          </p>
        )}

        {!addressStorage && (
          <p className="desktop-caption-regular text-restructure-secondary w-full restructure-tablet:w-auto">
            Consulte os prazos de entrega e retirada
          </p>
        )}

        <button
          className="desktop-caption-semibold text-restructure-action"
          data-testid="openModalSimulation"
          onClick={() => setOpenSimulationModal(true)}
        >
          {addressStorage ? 'Alterar CEP' : 'Adicionar CEP'}
        </button>
      </div>

      {readyToGetFreightSimulation && <FreightSimulation />}

      <FreightModal
        isOpen={isSimulationModalOpen}
        setOpenModal={setOpenSimulationModal}
        items={items}
      />
    </section>
  )
}
