import { useContext, useMemo } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts/product-context'
import type { ProductItem } from 'src/components/restructure/product/types/productItem'
import { Image } from 'src/components/ui/Image'
import classNames from 'classnames'

import DiscoutTag from '../../../../../../../images/icons/DiscoutTag'
import type { Variation } from '../types'

interface Props {
  variation: Variation
  onClickVariation: (name: any, value: string) => void
  items: ProductItem[]
}

type ItemByVariantName = {
  [key: string]: ProductItem
}

export function PictureSelector({ variation, onClickVariation }: Props) {
  const {
    product: { items },
  } = useContext(ProductContext)

  const variationName = variation.field.name

  const variationValuesList = useMemo(
    () => variation.values.map((variationValue) => variationValue.name),
    [variation.values]
  )

  const itemByVariantName: ItemByVariantName = useMemo(
    () =>
      variationValuesList.reduce(
        (acc, sku) => ({
          ...acc,
          [sku]:
            items.find(
              (item) =>
                item[variationName]?.[0] === sku && item.offer.hasDiscount
            ) ?? items.find((item) => item[variationName]?.[0] === sku),
        }),
        {}
      ),
    [items, variationName, variationValuesList]
  )

  const sortedVariationValues = [...variation.values].sort(
    (a, b) => (b as any).isAvailable - (a as any).isAvailable
  )

  const variationSelected = variation.values.find((item) => item.selected)

  return (
    <div className="wrapper-selector">
      <span className="desktop-body-semibold-text3 text-restructure-secondary">
        {variationName}:{' '}
        <span className="desktop-body-regular-text3 text-restructure-secondary">
          {variationSelected?.name.split('-')[0].toLowerCase()}
        </span>
      </span>

      <ul
        className="flex items-center flex-wrap gap-sm mt-md"
        key={variation.field.id}
        data-testid="picture-selector"
      >
        {sortedVariationValues.map((variationValue, index) => (
          <li key={variationValue.id} className="selector-item">
            <button
              className={classNames(
                'bg-restructure-background-secondary relative rounded-sm h-14 w-14 border border-transparent hover:border-restructure-border-action hover:text-restructure-action',
                {
                  '!border-restructure-border-action': variationValue.selected,
                },
                {
                  '!opacity-50 cursor-not-allowed !border-none':
                    !variationValue.isAvailable,
                }
              )}
              onClick={() =>
                onClickVariation(variationName, variationValue.name)
              }
              title={variationValue.name}
              disabled={!variationValue.isAvailable}
              data-testid={`variant-color-${index}`}
            >
              <Image
                key={itemByVariantName[variationValue.name]?.images[0].imageUrl}
                srcSet={
                  itemByVariantName[variationValue.name]?.images[0].imageUrl
                }
                src={itemByVariantName[variationValue.name]?.images[0].imageUrl}
                width={42}
                height={42}
                alt={
                  itemByVariantName[variationValue.name]?.images[0].imageText
                }
                style={{ objectFit: 'contain' }}
                preload
                className="bg-restructure-background-secondary mix-blend-multiply w-full"
              />

              {itemByVariantName[variationValue.name]?.offer.hasDiscount &&
                variationValue.isAvailable && (
                  <span className="absolute -top-2 -right-2" title="liquidação">
                    <DiscoutTag />
                  </span>
                )}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
