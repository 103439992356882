import { ProductQuantity } from 'src/components/restructure/product/sections/Sidebar/ProductAction/ProductQuantity'
import { BuyButton } from 'src/components/restructure/product/sections/Sidebar/ProductAction/BuyButton'
import { useProductPageContext } from 'src/components/restructure/product/contexts'
import { useEffect } from 'react'

import { useProductQuantity } from './ProductQuantity/hooks/useProductQuantity'

export function ProductAction() {
  const { count, updateValueQuantity } = useProductQuantity()

  const { product } = useProductPageContext()

  useEffect(() => {
    return updateValueQuantity(1, 'input')
  }, [updateValueQuantity, product.productId])

  return (
    <section className="mt-xl mb-4">
      <div className="flex flex-col restructure-tablet:flex-row items-center w-full gap-md restructure-tablet:gap-xs">
        <ProductQuantity
          quantity={count}
          updateValueQuantity={updateValueQuantity}
        />
        <BuyButton quantity={count} />
      </div>
    </section>
  )
}
