import { Statsig, useExperiment } from 'statsig-react'
import GiftCardModal from 'src/components/ui/Modal'
import { publish } from 'src/components/restructure/product/productEvents'
import { useProductPageContext } from 'src/components/restructure/product/contexts'
import { makeAddToCartEvent, sendEvent } from 'src/utils/restructure/analytics'

import { useBuyButton } from './hooks/useBuyButton'

interface BuyButtonProps {
  quantity: number
}

export function BuyButton({ quantity }: BuyButtonProps) {
  const { config: configLabel } = useExperiment(
    'teste_add_to_cart-comprar_agora'
  )

  const {
    product,
    currentSku,
    specificationMustBeSelected,
    isLoadingFreightSimulation,
    deliveryIsAvailable,
    setTriedToAdd,
  } = useProductPageContext()

  const { addToCart, openModal, setOpenModal } = useBuyButton()
  const { isAvailable } = currentSku

  const shouldDisable = isAvailable === false || isLoadingFreightSimulation

  const handleBuyButton = () => {
    setTriedToAdd(true)
    publish('addToCart')
    Statsig.logEvent('add_to_cart')

    if (!deliveryIsAvailable || specificationMustBeSelected) {
      return
    }

    const addToCartEvent = makeAddToCartEvent([
      { product, productItem: currentSku },
    ])

    sendEvent(addToCartEvent)
    Statsig.logEvent('add_to_cart')

    addToCart({
      id: currentSku.itemId,
      productId: product.productId,
      quantity,
      seller: currentSku.sellerDefault.sellerId,
      index: 0,
    })
  }

  const buttonLabel =
    (configLabel?.value?.button_label as string) ?? 'Adicionar ao carrinho'

  return (
    <>
      <button
        data-testid="buy-button"
        disabled={shouldDisable}
        className={`${
          shouldDisable
            ? 'cursor-not-allowed border-restructure-border-disabled bg-restructure-background-disabled text-restructure-disabled hover:border-restructure-border-disabled hover:text-restructure-disabled'
            : ''
        } flex-1 w-full restructure-tablet:w-auto hover:bg-restructure-background-action-1-hover bg-restructure-background-action-1 px-xxs py-sm rounded-round desktop-body-semibold-text2 text-restructure-primary-inverted`}
        onClick={handleBuyButton}
      >
        {buttonLabel}
      </button>

      <GiftCardModal
        message="Não é possível comprar o Cartão Presente junto de outros produtos. Inicie uma nova compra para realizar a ação."
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  )
}
