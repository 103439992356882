import { useCallback, useState } from 'react'
import { useUI } from 'src/sdk/ui'
import type { CartItem } from 'src/sdk/cart/validate'
import type { ProductItem } from 'src/components/restructure/product/types/productItem'
import type { Product } from 'src/components/restructure/product/types/product'
import { useProductPageContext } from 'src/components/restructure/product/contexts'
import { useCheckout } from 'src/sdk/checkout/useCheckout'
import { useExperiment } from 'statsig-react'
import { useToastContext } from 'src/components/ui/ToastMessage/ToastContext'

export interface ProductCartItem extends CartItem {
  id: string
  category: string
  price: number
  listPrice: number
  seller: {
    identifier: string
  }
  sellerName: string
  quantity: number
  productRefId: string
  arborescence: string
  productReference: string
  productId: string
  manufacturerCode: string
}

type Item = {
  id: string
  productId?: string
  seller: string
  quantity: number
  index: number
}

export function useBuyButton() {
  const [openModal, setOpenModal] = useState(false)

  const { specificationMustBeSelected, product, currentSku } =
    useProductPageContext()

  const { sendToast } = useToastContext()

  const { config } = useExperiment('remocao_minicart')
  const buyNowMinicart = config.value.buy_now_minicart

  const { addItems, getItems } = useCheckout()
  const { openMinicart } = useUI()

  const cartItems = getItems()

  const validateGiftCardRestrictions = useCallback(
    (item: Item) => {
      const isGiftCard = (productId: string | undefined) =>
        productId === '11113'

      const currentProductIsGiftCard = isGiftCard(item.productId)
      const cartContainsGiftCard = cartItems.some((cartItem) =>
        isGiftCard(cartItem.productId)
      )

      const cartContainsNonGiftCard = cartItems.some(
        (cartItem) => !isGiftCard(cartItem.productId)
      )

      if (
        (cartContainsNonGiftCard && currentProductIsGiftCard) ||
        (cartContainsGiftCard && !currentProductIsGiftCard)
      ) {
        setOpenModal(true)

        return true
      }

      return false
    },
    [cartItems]
  )

  const addToCart = useCallback(
    async (item: Item | Item[], shouldCheckSpecification = true) => {
      if (!item) {
        return
      }

      if (shouldCheckSpecification && specificationMustBeSelected) {
        return
      }

      const itemsToAdd = Array.isArray(item) ? item : [item]

      for (const itemToAdd of itemsToAdd) {
        if (validateGiftCardRestrictions(itemToAdd)) {
          return
        }
      }

      await addItems(itemsToAdd)

      if (buyNowMinicart) {
        sendToast({
          text: 'Item adicionado ao carrinho!',
          subtext: product.productName,
          image: currentSku.images[0].imageUrl,
          variant: 'success',
        })

        return
      }

      openMinicart()
    },
    [
      specificationMustBeSelected,
      validateGiftCardRestrictions,
      addItems,
      buyNowMinicart,
      openMinicart,
      sendToast,
      product.productName,
      currentSku.images,
    ]
  )

  const mapperCartItem = (productToMap: Product, item: ProductItem): Item => {
    return {
      id: item.itemId,
      productId: productToMap.productId,
      seller: item.sellerDefault.sellerId,
      quantity: 1,
      index: 0,
    }
  }

  return {
    addToCart,
    mapperCartItem,
    openModal,
    setOpenModal,
    validateGiftCardRestrictions,
  }
}
