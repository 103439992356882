import { useCallback, useContext, useState, useEffect, useMemo } from 'react'
import { useUI } from 'src/sdk/ui'
import { getInstallment } from 'src/utils/formatted'
import type { ProductItem } from 'src/components/restructure/product/types/productItem'
import { ProductContext } from 'src/components/restructure/product/contexts/product-context'
import type { DataRecommendation } from 'src/components/restructure/product/types/linx-recommendations'
import { RecommendationContext } from 'src/components/restructure/product/contexts/recommendations-context'
import { useBuyButton } from 'src/components/restructure/product/sections/Sidebar/ProductAction/BuyButton/hooks/useBuyButton'
import {
  makeBuyTogetherEventTrack,
  makeAddToCartBuyTogetherEvent,
  sendEvent,
} from 'src/utils/restructure/analytics'

import { generateCartItems } from './utils/generateCartItems'
import type { ActiveItem, Product, Items } from './utils/types'
import { generateNewActiveItem } from './utils/generateNewActiveItem'

export const useShelfBoughtTogether = () => {
  const { dataRecommendation } = useContext(RecommendationContext)
  const { currentSku } = useContext(ProductContext)
  const { addToCart, validateGiftCardRestrictions } = useBuyButton()
  const [openGiftCardAlert, setOpenGiftCardAlert] = useState(false)
  const { openMinicart } = useUI()

  const dataShelf = dataRecommendation?.filter((item: DataRecommendation) =>
    item.showcases.some(
      (showcase) => showcase.feature === 'FrequentlyBoughtTogether'
    )
  )

  const recommendations = useMemo(
    () => (dataShelf?.length && dataShelf?.[0].showcases?.[0]) || null,
    [dataShelf]
  )

  const allItems = useMemo(
    () => recommendations?.displays?.[0].recommendations ?? [],
    [recommendations]
  )

  const currentItem = useMemo(
    () => recommendations?.displays?.[0].references[0] ?? {},
    [recommendations]
  )

  const [dataLoaded, setDataLoaded] = useState(false)
  const [itemsActive, setItemsActive] = useState<Product[]>(
    allItems?.slice(0, 2) ?? []
  )

  useEffect(() => {
    if (allItems?.length > 0) {
      setItemsActive(allItems?.slice(0, 2) ?? [])
      setDataLoaded(true)
    }
  }, [allItems])

  const [nextActiveItemIndex, setNextActiveItemIndex] = useState(2)

  const productBuyTogether = [currentItem, ...itemsActive]

  const itemsFinally = [] as any[]

  const currentItemSku = currentItem?.items?.filter(
    (item: ProductItem) => item.itemId === currentSku.itemId
  )

  itemsFinally.push({ skuData: currentItemSku?.[0] })
  productBuyTogether.slice(1, 3).forEach((item) => {
    if (item?.sku) {
      itemsFinally.push({
        skuData: item.items.find(
          (itemActive: Items) => itemActive.itemId === item.sku
        ),
      })
    } else {
      itemsFinally.push({ skuData: item.items[0] })
    }
  })

  const totalPriceItemsCalc = itemsFinally?.reduce((acc: number, item) => {
    const price: number = item?.skuData?.offer?.price ?? 0

    return acc + price
  }, 0)

  const [decimalValue, centsValue] = String(totalPriceItemsCalc).split('.')
  const inst = totalPriceItemsCalc && getInstallment(totalPriceItemsCalc)
  const handleChangedItemArraySKU = useCallback(
    (setedItem: ActiveItem) => {
      const itemsArray = [...itemsActive]

      if (Object.keys(setedItem).length === 0 || !setedItem) {
        return
      }

      const arrayIndex = itemsArray.findIndex(
        (arrayItem) =>
          arrayItem.items?.find(
            (itemCustomData: Items) =>
              itemCustomData.itemId === setedItem.itemId
          )?.itemId === setedItem.itemId
      )

      const selectedItem = itemsArray[arrayIndex]

      if (selectedItem === undefined) {
        return
      }

      const newActiveItem = generateNewActiveItem(setedItem, selectedItem)

      itemsArray[arrayIndex] = newActiveItem
      setItemsActive(itemsArray)
    },
    [itemsActive]
  )

  function handleDeleteCard(productId: string) {
    if (itemsActive.length <= 1) {
      return
    }

    setItemsActive((prevValues) => {
      const currentProduct = prevValues.filter(
        (item: { productId: string }) => item.productId === productId
      )

      sendActionEvent('remover', currentProduct?.[0])

      return prevValues.filter((product) => product.productId !== productId)
    })
  }

  function sendActionEvent(action: string, product: Product) {
    const event = makeBuyTogetherEventTrack({
      eventAction: action,
      eventPage: 'página de produto',
      product,
    })

    sendEvent(event)
  }

  function handleReloadCard(productId: string) {
    const currentProductIndex = itemsActive.findIndex(
      (product) => product.productId === productId
    )

    let nextIndex = nextActiveItemIndex

    for (const _item of allItems) {
      if (nextIndex > allItems?.length - 1) {
        nextIndex = 0
      }

      const nextProduct = allItems?.[nextIndex]
      const isItemVisible = itemsActive?.some(
        (product) => product.productId === nextProduct.productId
      )

      if (isItemVisible) {
        nextIndex += 1
      } else {
        break
      }
    }

    setItemsActive((prevValues) => {
      const nextProduct = allItems?.[nextIndex]
      const currentProduct = allItems?.[nextIndex - 1]

      sendActionEvent('ver outro', currentProduct)

      prevValues[currentProductIndex] = nextProduct

      return prevValues
    })
    setNextActiveItemIndex(nextIndex + 1)
  }

  async function handleAddItemsToCart() {
    try {
      const { currentSkus, cartItems } = generateCartItems(
        currentItem,
        currentSku,
        itemsActive
      )

      const addToCartEvent = makeAddToCartBuyTogetherEvent(currentSkus)

      sendEvent(addToCartEvent)

      const mappedItems = cartItems.map((item, index) => {
        return {
          productId: item.productId,
          id: item.id,
          seller: item.seller.identifier,
          quantity: 1,
          index,
        }
      })

      for (const item of mappedItems) {
        if (validateGiftCardRestrictions(item)) {
          setOpenGiftCardAlert(true)

          return
        }
      }

      await addToCart(mappedItems, false)
      openMinicart()
    } catch (error) {
      console.warn('error', error)
    }
  }

  function checkItemsActiveLength(length: number) {
    return length === 1 ? 'last-item-expanded' : ''
  }

  function checkQuantity() {
    if (currentItem) {
      return (
        currentItem?.items?.[0].sellers?.[0].commertialOffer
          ?.AvailableQuantity > 0
      )
    }

    return false
  }

  return {
    dataLoaded,
    itemsActive,
    currentItem,
    currentItemSku,
    decimalValue,
    centsValue,
    inst,
    productBuyTogether,
    recommendations,
    openGiftCardAlert,
    setOpenGiftCardAlert,
    handleChangedItemArraySKU,
    handleDeleteCard,
    handleAddItemsToCart,
    handleReloadCard,
    checkQuantity,
    checkItemsActiveLength,
  }
}
