import { useEffect, useState, useMemo } from 'react'
import type {
  DataRecommendation,
  Showcases,
} from 'src/components/restructure/product/types/linx-recommendations'
import { useRecommendationContext } from 'src/components/restructure/product/contexts/recommendations-context'

import type { Product } from '../../types/product'
import SimpleShelf from '../SimpleShelf'

type BuyTogetherShelfProps = {
  sport: string
}

const BuyTogetherShelf = ({ sport }: BuyTogetherShelfProps) => {
  const { dataRecommendation } = useRecommendationContext()
  const [buyTogetherProducts, setBuyTogetherProducts] = useState<Product[]>([])
  const [title, setTitle] = useState('')
  const [impressionUrl, setImpressionUrl] = useState('')

  const buyTogetherRecommendation = useMemo(() => {
    return dataRecommendation?.filter((item: DataRecommendation) =>
      item.showcases.some(
        (showcase) => showcase.feature === 'FrequentlyBoughtTogether'
      )
    )
  }, [dataRecommendation])

  useEffect(() => {
    if (!buyTogetherRecommendation) {
      return
    }

    const buyTogetherShowcase = buyTogetherRecommendation[0]?.showcases?.find(
      (showcase: Showcases) => {
        return showcase.feature === 'FrequentlyBoughtTogether'
      }
    )

    if (!buyTogetherShowcase?.displays[0]?.recommendations) {
      return
    }

    setBuyTogetherProducts(
      buyTogetherShowcase?.displays[0]?.recommendations.slice(0, 4)
    )

    setTitle(buyTogetherShowcase.title)

    setImpressionUrl(buyTogetherShowcase.impressionUrl)
  }, [buyTogetherRecommendation])

  return (
    <SimpleShelf
      products={buyTogetherProducts}
      title={title}
      impressionUrl={impressionUrl}
      sport={sport}
    />
  )
}

export default BuyTogetherShelf
