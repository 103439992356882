import { useEffect, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import Icon from 'src/components/ui/Icon/Icon'
import { formatterPrice } from 'src/utils/formatterPrice'
import { useProductPageContext } from 'src/components/restructure/product/contexts/product-context'
import { subscribe } from 'src/components/restructure/product/productEvents'
import { InformationIcon } from 'src/components/Icons/InformationIcon'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'

const isB2B = checkEnviromentIsB2B()

export function FreightSimulation() {
  const {
    deliveryIsAvailable,
    isLoadingFreightSimulation,
    freightSimulationValue,
  } = useProductPageContext()

  const deliveryAlertRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!deliveryIsAvailable && isB2B) {
      subscribe('addToCart', () => {
        if (!deliveryAlertRef?.current) {
          return
        }

        window.scroll({
          top: deliveryAlertRef?.current?.offsetTop - 118,
          behavior: 'smooth',
        })
      })
    }
  }, [deliveryIsAvailable])

  function formatDeliveryTpeName(deliveryType: string) {
    return deliveryType
      .replace('economic', 'Econômica:')
      .replace('pick-up', 'Retire na loja:')
      .replace('fast', 'Rápido:')
  }

  function getDeliveryTypeIcon(deliveryType: string) {
    const iconName = deliveryType
      .replace('economic', 'EconomicFreight')
      .replace('pick-up', 'PickUpFreight')
      .replace('fast', 'FastFreight')

    return <Icon name={iconName} width={20} height={20} />
  }

  return (
    <div data-testid="resume-simulation-shipping">
      {!isLoadingFreightSimulation && freightSimulationValue && (
        <ul>
          {deliveryIsAvailable ? (
            freightSimulationValue.packages.map((packageItem) => (
              <li key={packageItem.seller}>
                <ul className="flex flex-col gap-sm mt-md">
                  {packageItem.deliveryTypes.map((deliveryType) => (
                    <li
                      className="flex items-center gap-xxs"
                      key={deliveryType.type}
                    >
                      {getDeliveryTypeIcon(deliveryType.type)}
                      <span className="mobile-body-regular-text3 text-restructure-primary">
                        {formatDeliveryTpeName(deliveryType.type)}
                      </span>
                      <span className="text-restructure-tertiary">
                        {deliveryType.shippingEstimate}
                      </span>

                      {deliveryType.value === 0 ? (
                        <p className="desktop-body-regular-text3 text-restructure-success mr-0 ml-auto">
                          Grátis
                        </p>
                      ) : (
                        <p className="desktop-body-regular-text3 text-restructure-primary mr-0 ml-auto">
                          {formatterPrice(deliveryType.value / 100)}
                        </p>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
            ))
          ) : (
            <div
              ref={deliveryAlertRef}
              className="text-restructure-error font-inter text-xs mt-4 flex"
            >
              <InformationIcon color="#CC212A" width={24} height={24} />
              <p className="ml-1 pt-1">
                Este produto está indisponível para este CEP. Altere ou insira
                outro CEP para calcular o frete.
              </p>
            </div>
          )}
        </ul>
      )}
      {isLoadingFreightSimulation && (
        <div className="flex flex-col gap-sm mt-md" data-testid="skeleton">
          <Skeleton className="w-full" height={24} />
          <Skeleton className="w-full" height={24} />
        </div>
      )}
    </div>
  )
}
